import { ConnectButton } from '@rainbow-me/rainbowkit'
import React from 'react'

interface IProps {
  children: React.ReactNode
}

const BasicLayout: React.FC<IProps> = ({ children }) => {
  return (
    <div className='min-h-screen w-full flex flex-col items-center bg-cover bg-fixed bg-center bg-no-repeat bg-origin-border' >
      <>
        <header className='w-350 fixed z-27.5'>
          <div className='flex justify-end items-center h15'>
            <ConnectButton /></div>
        </header>
        <div className="w-full h-1 bg-#ccc fixed top-15 shadow-lg shadow-black"></div>
      </>

      <div className="h-25"></div>

      <div className="h7.5 w-full" />

      <main className='h-full w-330 items-center'>
        {children}
      </main>

      <div className='h-15 w-full' />
    </div>
  )
}

export default BasicLayout
