import 'uno.css'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import App from './App.tsx'
import './index.css'
import { WagmiConfig, } from 'wagmi'
import {
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit'
import { chains, wagmiConfig } from './utils/connectWallet.ts'

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <SnackbarProvider>
    <BrowserRouter>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} >
          <App />
        </RainbowKitProvider>
      </WagmiConfig>
    </BrowserRouter>
  </SnackbarProvider>
)