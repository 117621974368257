import type { RouteObject } from 'react-router-dom'
import React, { lazy } from 'react'
import BasicLayout from '../layouts/BasicLayout'

const Index = lazy(() => import('../pages/index'))

interface IRouterMeta {
  title?: string
  icon?: string
}

export type IRouter = (RouteObject & { meta?: IRouterMeta })

const routes: IRouter[] = [
  {
    path: '/',
    element: (
      <React.Suspense fallback={<div> Loading...</div>}>
        <BasicLayout><Index/></BasicLayout>
      </React.Suspense>
    ),
  },
]

export default routes
