import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, } from 'wagmi';
import {
  arbitrum,
  polygonMumbai
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const localhost = {
  ...polygonMumbai,
  id: 31337,
  name: 'Localhost',
  network: 'localhost',
  rpcUrls: {
    ...polygonMumbai.rpcUrls,
    ...{
      localhost: {
        http: [import.meta.env.VITE_ALCHEMY_ID,],
        webSocket: ['wss://polygon-mumbai.g.alchemy.com/v2/k_wjgD_vDNows-mCiUJ_ikKh-0Gd2c0C'],
      },
    },
  },
}

const chainList = [polygonMumbai, arbitrum]

if (import.meta.env.DEV)
  chainList.unshift(localhost as any)

const { chains, publicClient } = configureChains(
  chainList,
  [
    alchemyProvider({
      apiKey: import.meta.env.VITE_ALCHEMY_ID,
    }),
    publicProvider(),
  ],
)

const { connectors } = getDefaultWallets({
  appName: import.meta.env.VITE_DAPP_TITLE,
  projectId: import.meta.env.VITE_PROJECT_ID_FOR_WALLETS,
  chains,
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

export { wagmiConfig, chains }